import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";

import { RouteName } from "@/consts/RouteName";
import { commonTranslations } from "@/consts/pageTranslationNamespace";
import SiteLayout from "@/features/common/components/navigation/SiteLayout";
import { useUserContext } from "@/features/user/contexts/useUserContext";
import { getLoginSession } from "@/services/auth/userCookies";

const LandingPage = dynamic(
  () => import("@/features/landing/components/LandingPage"),
  { ssr: true },
);

function Landing() {
  const { user, ready } = useUserContext();
  const router = useRouter();

  useEffect(() => {
    if (ready && user) router.push(RouteName.Home);
  }, [ready, user, router]);

  return <LandingPage />;
}

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  req,
}) => {
  const { ssrUserId } = getLoginSession(req) ?? {};

  if (ssrUserId) {
    return {
      redirect: {
        permanent: false,
        destination: RouteName.Home,
      },
      props: {},
    };
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        ...commonTranslations,
        "topWriters",
        "post",
        "landing",
      ])),
    },
  };
};

Landing.getLayout = function getLayout(page: ReactElement) {
  return (
    <SiteLayout showFooter margin="my-16 md:mt-0 md:mb-12">
      {page}
    </SiteLayout>
  );
};

export default Landing;
